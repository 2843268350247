export const userTableCols = [
  {
    label: '分销商',
    prop: 'mainUser',
    slotName: 'mainUser'
  },
  {
    label: '订单量',
    prop: 'orderCount',
    sortable: 'custom'
  },
  {
    label: '销售产品数量',
    prop: 'productCount',
    sortable: 'custom'
  },
  {
    label: '销售额',
    prop: 'productAmount',
    sortable: 'custom'
  },
  {
    label: '业绩详情',
    prop: 'operation',
    slotName: 'operationSlot'
  }
]
